import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import HomePage from "./pages/HomePage";
import Blog from "./pages/Blog";
import Post1 from "./pages/Post1";
import Post2 from "./pages/Post2";
import * as serviceWorker from "./serviceWorker";
import "normalize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router } from "react-router-dom";
import WebFont from "webfontloader";

const routing = (
  <Router>
    <div>
      <Route exact path='/' component={HomePage} />
      <Route exact path='/blog' component={Blog} />
      <Route exact path='/posts/1' component={Post1} />
      <Route exact path='/posts/2' component={Post2} />
    </div>
  </Router>
);

WebFont.load({
  google: {
    families: [
      "Playfair Display:900",
      "Raleway:900",
      "Roboto:300,400,900",
      "serif",
    ],
  },
});

ReactDOM.render(routing, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

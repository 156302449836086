import React from "react";
import Header from "../components/Header/Header";
import Section from "../components/Section/Section";
import Footer from "../components/Footer/Footer";
import posts from "../assets/texts/posts";
import ContactCard from "../components/Footer/ContactSection/Card";
import styles from "./Pages.module.css";
import SectionTitle from "../components/Section/SectionTitle/SectionTitle";
import SectionText from "../components/Section/SectionText/SectionText";
import SectionSubtitle from "../components/Section/SectionSubtitle/SectionSubtitle";

const Post1 = () => {
  return (
    <div>
      <Section pageHeader>
        <Header />
      </Section>
      <section className={styles.blog}>{renderBlogPost()}</section>
      <Footer />
    </div>
  );
};

const renderBlogPost = () => (
  <article className={styles.section}>
    <SectionTitle dark>Mon interview avec Thomas Carreira</SectionTitle>
    <div className={styles.post__meta}>
      <span
        className={[
          styles["post__meta-item"],
          styles["post__meta--first-child"],
        ]}
      >
        Samy Abouseda{" "}
      </span>
      •<span className={styles["post__meta-item"]}>15 avril 2019</span>•
      <span className={styles["post__meta-item"]}>3 Minutes</span>
    </div>
    <SectionText dark>
      Durant le cours de Business Digital Coaching nous avons dû effectuer une
      interview par binôme. J’ai donc expliqué à{" "}
      <a
        className={styles.post__link}
        href='https://thomascarreiraportfolio.wordpress.com'
      >
        {" "}
        Thomas Carreira{" "}
      </a>
      ce que je faisais dans la vie et ce que j’aimais particulièrement. Il
      s'est ensuite chargé d'y retranscrire, ce qui m'a permis de rédiger cet
      article.
    </SectionText>
    <br />
    <SectionText dark>
      <strong>Thomas:</strong>« Salut Samy, peux-tu te présenter brièvement en
      nous parlant de toi et de ce que tu aimes dans la vie ? »
    </SectionText>
    <br />
    <SectionText dark>
      <strong>Moi:</strong>« Je m’appelle Samy Abouseda, je suis né en 1996 (je
      vous laisse faire le calcule) et je vis à Genève depuis ma naissance. Je
      poursuis actuellement la formation d’informaticien de gestion de la{" "}
      <a className={styles.post__link} href='https://www.hesge.ch/heg/'>
        HEG de Genève
      </a>
      . Une de mes passions qui m'a suivit tout au long de ma vie est le sport.
      J'ai toujours aimé repousser mes limites et devenir plus résistant, tant
      mentalement que physiquement. Je pratique actuellement du PowerLifting,
      mais j'ai également pratiqué toute une multitude de sports en passant par
      le football américain en jouant au{" "}
      <a className={styles.post__link} href='https://seahawks.ch/'>
        Seahawks de Genève
      </a>
      . Je suis également pratiquant de méditation, ce qui m'aide à me recentrer
      et me canaliser. C'est d'ailleurs durant ma routine matinale que je médite
      et c'est elle qui me permet de bien commencer une journée et d'être de
      bonne humeur tout en étant productif ! Une autre passion que j'ai
      découverte (malheureusement) assez tardivement est la programmation. Je
      peux passer des heures à écrire des lignes de code et j'adore imaginer et
      conceptualiser des solutions informatiques qui répondent aux besoins de
      tous les jours. »
    </SectionText>
    <br />
    <SectionText dark>
      <strong>Thomas:</strong>« Peux-tu nous décrire ton parcours et nous
      expliquer ce qui ta poussé à entreprendre une formation d'informaticien de
      gestion ?  »
    </SectionText>
    <br />
    <SectionText dark>
      <strong>Moi:</strong>« Après ma dernière année d’école obligatoire en
      2012, je ne savais pas encore exactement ce que je voulais faire, mais
      j'avais toujours été intéressé par l'entrepreneuriat et par le marketing.
      J'ai donc entrepris une maturité professionnelle et un CFC d'employé de
      commerce. Suite à ça, j'ai pu effectuer une année de stage au sein de{" "}
      <a className={styles.post__link} href='https://us.pg.com/'>
        Procter & Gamble{" "}
      </a>
      dans le département Marketing. C'est durant cette année de stage que j'ai
      été mordu par l'informatique. En effet, un de mes collègues avait
      absolument besoin d'une application lui permettant de customiser une
      certaine gamme de produit pour pouvoir présenter des maquettes aux
      prospects. N'ayant pas les fonds nécessaires pour engager des
      développeurs, j'ai décidé de voir ce que j'arriverais à faire avec les
      moyens du bord. Après de nombreuses heures de recherches et quelques
      neurones cramés, j'ai finalement réussi à réaliser l'application dont il
      avait besoin. C'est là que j'ai su ce que je voulais faire et que j'ai
      découvert ce qui me passionne !»
    </SectionText>
  </article>
);

export default Post1;

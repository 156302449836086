const images = {
  me: require("./me.png"),
  logo: require("./logo.png"),
  hero: require("./hero.jpg"),
  our_protocols: require("./dentist_smiling.jpg"),
  maps: {
    geneva: require("./map_geneva.png"),
  },
  clinics: {
    geneva: {
      hero: require("./dental_clinic_geneva--hero.jpg"),
      cabinet: require("./dental_clinic_geneva_cabinet.jpg"),
    },
    dubai: {
      hero: require("./dental_clinic_dubai--hero.jpg"),
    },
  },
  legal_notice: {
    hero: require("./legal_notice_background.jpg"),
  },
  global: {
    default_avatar: require("./default_avatar.png"),
  },
  contact: {
    geneva: require("./dental_clinic_geneva--hero.jpg"),
    dubai: require("./dental_clinic_dubai--hero.jpg"),
  },
  dummy: {
    woman_profile: require("./dummy/woman-upper-body.png"),
  },
};

export default images;

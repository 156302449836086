const generation_y = {
  url: "/posts/2",
  title: 'Qui sont ces "Digital Natives" ?',
  img: require("../../images/milenials.jpg"),
  excerpt:
    "Qui sont les digital natives de notre société ? Qu'est-ce qui les différencie des autres générations ? Et qu'est-ce qui fait d'eux ce qu'ils sont ? On peut se poser tout un tas de questions sur ce type de personne.",
  info: {
    date: "15 mai 2019",
    readtime: 7,
  },
  body: {
    sections: [
      {
        p:
          "Pour commencer, cette expression représente les personnes qui sont nées pendant l’explosion des nouvelles technologies. Ils sont également appelés digital native mais qu’est-ce que cela signifie vraiment … Ce sont des gens qui, pour la plupart, par exemple, n’ont jamais eu à sortir de chez eux pour aller à la bibliothèque communale afin de chercher une information qu’ils ne trouveront finalement pas. Ou bien encore, d’aller sonner chez ses amis au lieu de tout simplement leur envoyer un sms …Cette génération est née avec les nouvelles technologies, elle s’est donc habituée à les utiliser pour le meilleur et pour le pire et certains points concernant leur façon d’agir reviennent régulièrement. Comme par exemple : le fait qu’ils seraient multi-tâches, qu’ils auraient un rapport au temps différent, que leur approche des nouvelles technologies serait plus intuitive que celle d’un digital migrant ou qu’ils pratiqueraient une lecture sautillante. Nous allons donc essayer d’analyser ces 4 différentes théories.",
      },
      {
        h2: "Multi-tâches",
        p:
          "Tout d’abord, j’aurais tendance à dire que la génération Y à tendance à faire plusieurs choses en même temps ce qui les rend par définition multi-tâche en revanche il serait judicieux d’étudier les différentes tâches qu’ils font en parallèle. Car, selon la tâche à réaliser, si vous souhaitez qu’elle soit faite le plus efficacement possible il va vous falloir vous focaliser dessus à 100% et le fait qu’ils aient tendance à faire plusieurs choses en même temps ne va pas leur rendre service mais bien au contraire.",
      },
      {
        h2: "Rapport au temps différents",
        p:
          "Cette génération a toujours eu accès à toutes sortes d’informations en tout temps, tout lieu et de manière instantané. Cela implique-t-il une approche au temps différente. J’aurais tendance à dire que c’est plus une question d’éducation qui les rendraient plus ou moins patients.",
      },
    ],
  },
};

export default generation_y;

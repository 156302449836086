import React from "react";
import styles from "./NavbarWrapper.module.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";

class NavbarWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <div>
        <Navbar light expand='md'>
          <NavbarBrand className={styles["navbar-brand"]} href='/'>
            Samy Abouseda
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <NavItem>
                <div className={styles.navlink}>
                  <Link smooth to='/'>
                    Accueil
                  </Link>
                </div>
              </NavItem>

              <NavItem>
                <div className={styles.navlink}>
                  <Link smooth to='/blog'>
                    Blog
                  </Link>
                </div>
              </NavItem>

              <NavItem>
                <div className={styles.navlink}>
                  <Link smooth to='/#experiences_and_studies'>
                    Expérience et compétences
                  </Link>
                </div>
              </NavItem>
              <NavItem>
                <div className={styles.navlink}>
                  <Link smooth to='/#vision'>
                    Vision
                  </Link>
                </div>
              </NavItem>

              <NavItem>
                <div className={styles.navlink}>
                  <Link smooth to='#contact'>
                    Contact
                  </Link>
                </div>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavbarWrapper;

import React from "react";
import Header from "../components/Header/Header";
import Section from "../components/Section/Section";
import Footer from "../components/Footer/Footer";
import posts from "../assets/texts/posts";
import ContactCard from "../components/Footer/ContactSection/Card";
import styles from "./Pages.module.css";
import SectionTitle from "../components/Section/SectionTitle/SectionTitle";
import SectionSubtitle from "../components/Section/SectionSubtitle/SectionSubtitle";
import SectionText from "../components/Section/SectionText/SectionText";

const Post2 = () => {
  return (
    <div>
      <Section pageHeader>
        <Header />
      </Section>
      <section className={styles.blog}>{renderBlogPost()}</section>
      <Footer />
    </div>
  );
};

const renderBlogPost = () => (
  <article className={styles.section}>
    <SectionTitle dark>Qui sont ces "Digital Natives" ?</SectionTitle>
    <div className={styles.post__meta}>
      <span
        className={[
          styles["post__meta-item"],
          styles["post__meta--first-child"],
        ]}
      >
        Samy Abouseda{" "}
      </span>
      •<span className={styles["post__meta-item"]}>20 mai 2019</span>•
      <span className={styles["post__meta-item"]}>7 Minutes</span>
    </div>
    <SectionText dark>
      Qui sont les digital natives de notre société ? Qu'est-ce qui les
      différencie des autres générations ? Et qu'est-ce qui fait d'eux ce qu'ils
      sont ? On peut se poser tout un tas de questions sur ce type de personne.{" "}
    </SectionText>
    <br />
    <SectionText dark>
      Tout d'abord, "digital native" est un terme inventé par Mark Prensky en
      2001 pour décrire la génération de personnes ayant grandi à l'ère
      numérique. Les natifs du numérique sont à l'aise avec la technologie et
      les ordinateurs dès leur plus jeune âge et considèrent la technologie
      comme une partie intégrante et nécessaire de leur vie. A l'opposé des
      natifs numériques ont retrouve les immigrants numériques, des personnes
      qui ont dû s'adapter au changement technologique abrupte et qui ont de la
      peine à parler le nouveau langage de la technologie. Les natifs du
      numérique sont souvent utilisés pour décrire le fossé numérique en termes
      de capacité d'apprentissage et d'utilisation technologique des personnes
      nées à partir des années 1980-90 et des personnes nées avant.
    </SectionText>
    <br />
    <br />
    <SectionSubtitle dark>Ils croient être multi-tâches</SectionSubtitle>
    <SectionText dark>
      Une grande partie des digital natives se disent être capables d'être
      "multi-tâches", ils en sont même fières. Ils pensent devoir être
      multi-tâches pour optimiser leur efficacité et leur productivité à tout
      moment. Comment pourraient-ils autrement répondre aux demandes de leur
      vies trépidantes et surchargées?
    </SectionText>

    <br />
    <SectionText dark>
      En effet, la plupart d’entre eux pensent que le multi-tâches est une
      partie nécessaire de la vie et que c'est la solution à leurs problèmes.
      Mais qu'en est-il réellement ? Qu'est-ce que cela signifie vraiment? Bien
      qu'ils arrivent à donner l'impression et à se donner l'impression d'être
      multi-tâches, ils passent en fait complètement d'une tâche à une autre.
      Même s'ils ont l’impression d'avoir la capacité de lire leur courrier
      électronique, de parler au téléphone et de poster un Twitt en même temps,
      c’est littéralement impossible.
    </SectionText>

    <br />
    <SectionText dark>
      Certaines recherches convaincantes de l'
      <a
        href='https://www.apa.org/research/action/multitask'
        className={styles.post__link}
      >
        {" "}
        American Psychological Association{" "}
      </a>
      montrent que ce que l'on pense être du multi-tâches est en réalité
      contre-productif. Selon les études, lorsque l'on passe d’une tâche à
      l’autre, la transition n’est pas douce. Il y a un temps de latence pendant
      que le cerveau déplace l'attention d'une tâche à une autre. Et même s’il
      semble que ce changement s’effectue sans chocs et instantanément, cela
      prend du temps.
    </SectionText>

    <br />
    <br />
    <SectionSubtitle dark>
      Ils ont un rapport au temps différent
    </SectionSubtitle>

    <SectionText dark>
      Les natifs du numérique ont la réputation d'être impatients en matière de
      développement professionnel. Mais qu'en est-il vraiment ? Et surtout, à
      quoi cela est-il dû ?
    </SectionText>
    <br />
    <SectionText dark>
      Cette génération est née dans une période où tout est plus rapide, plus
      agile et plus dynamique. Ils n'ont pas connu le temps de chargement des
      premières images sur internet... Ils travaillent dans un monde où les
      normes sociales changent - tout devient de plus en plus instantané, de
      plus en plus rapide et de plus en plus qualitatif. Il n'est donc pas
      surprenant qu’ils attendent la même chose sur leur lieu de travail.
    </SectionText>

    <br />
    <br />
    <SectionSubtitle dark>
      Ils ont une approche intuitive des nouvelles technologies
    </SectionSubtitle>
    <SectionText dark>
      Les digital natives ont une approche des nouvelles technologies plus
      intuitive, au même titre qu’un pilote de course avec une nouvelle voiture.
      A force de devoir s'adapter perpétuellement dans un monde qui change à une
      allure très soutenue, ils développent une certaine agilité et un certain
      mécanisme d'apprentissage plus optimal que celui de leurs ainés.
    </SectionText>
    <br />
    <SectionText dark>
      Ils sont donc instinctivement plus doués pour tout ce qui touche de près
      ou de loin aux nouvelles technologies et ont plus de facilité à adopter
      une nouvelle technologie. Voire même de la promouvoir grâce aux bienfaits
      perçus instantanément.
    </SectionText>

    <br />
    <br />
    <SectionSubtitle dark>
      Ils pratiquent la lecture sautillante
    </SectionSubtitle>
    <SectionText dark>
      Avec la pléthore d'information disponible au bout d'un clic sur internet,
      il est de plus en plus difficile de filtrer les informations et de trouver
      celle que l'on cherchait initialement. Lire la totalité d'une recherche
      Google n'est même pas envisageable du fait que les informations sont liées
      de manière infinie. Les natifs ont donc du trouver un stratagème qui leur
      permet d'obtenir l'information désirée et ce en très peu de temps.
    </SectionText>
    <br />
    <SectionText dark>
      Ils pratiquent donc la lecture sautillante, qui consiste à survoler le
      texte pour se faire une idée de la pertinence de la ressource en question.
    </SectionText>

    <br />
    <br />
    <SectionSubtitle dark>Conclusion</SectionSubtitle>

    <SectionText dark>
      Il semblerait donc que les natifs du numérique font partie d'une
      génération qui cherche à aller toujours plus vite, toujours plus loins et
      de la manière la plus efficace qui soit. Toutefois, leur impatience à un
      prix qu'ils payent sur la qualité de leur travail et leur surmenage.
    </SectionText>
  </article>
);

export default Post2;

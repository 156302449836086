import React, { Component } from "react";
import Fade from "react-reveal/Fade";

import styles from "./Card.module.css";

class ContactCard extends Component {
  render() {
    const { full, children } = this.props;
    let classNames = `${full ? styles.full : ""} ${styles.card}`;
    return (
      <Fade bottom>
        <div className={classNames}>{children}</div>
      </Fade>
    );
  }
}

export default ContactCard;

import React, { Component } from "react";
import styles from "./Header.module.css";
import NavbarWrapper from "./NavbarWrapper";

class Header extends Component {
  render() {
    return (
      <header className={styles.header}>
        <NavbarWrapper />
      </header>
    );
  }
}

export default Header;

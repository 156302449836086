import React, { Component } from "react";
import styles from "./Section.module.css";

class Section extends Component {
  render() {
    const {
      backgroundImage,
      pageHeader,
      dark,
      light,
      hero,
      narrow,
    } = this.props;

    let classNames = `
      ${styles.section} 
      ${dark ? styles["section--dark"] : ""}
      ${light ? styles["section--light"] : ""} 
      ${hero ? styles["section--hero"] : ""} 
      ${narrow ? styles["section--narrow"] : ""} 
      ${pageHeader ? styles["section--page-header"] : ""} 
    `;

    if (backgroundImage) {
      return this.renderSectionWithBgImage(classNames);
    } else {
      return this.renderSectionWithoutBgImage(classNames);
    }
  }

  renderSectionWithoutBgImage = classNames => (
    <div id={this.props.id} className={classNames}>
      {this.props.backgroundImage && (
        <div className={styles["section--bg-image-overlay"]} />
      )}
      {this.props.children}
    </div>
  );

  renderSectionWithBgImage = classNames => (
    <div
      id={this.props.id}
      className={classNames}
      style={{
        backgroundImage: `url(${this.props.backgroundImage})`,
      }}
    >
      {this.props.backgroundImage && (
        <div className={styles["section--bg-image-overlay"]} />
      )}
      {this.props.children}
    </div>
  );
}

export default Section;

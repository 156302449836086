import React from "react";
import styles from "./Footer.module.css";
import lkd from "./linkedin-draw-logo.svg";
import twt from "./twitter-draw-logo-2.svg";
import mail from "./post-envelope.svg";

const currentyear = new Date().getFullYear();

const Footer = props => {
  return (
    <footer className={styles.footer} id={props.id}>
      <div className={styles.container}>
        <div className={styles.social__container}>
          <a href={"mailto:samy@abouseda.ch"}>
            <img className={styles.social__link} alt='mail' src={mail} />
            <p className={styles.footer__caption}>samy@abouseda.ch</p>
          </a>
          <a href='https://www.linkedin.com/in/samyabouseda/'>
            <img className={styles.social__link} alt='linkedin' src={lkd} />
            <p className={styles.footer__caption}>linkedin</p>
          </a>
          <a href='https://twitter.com/abousedas'>
            <img className={styles.social__link} alt='twitter' src={twt} />
            <p className={styles.footer__caption}>twitter</p>
          </a>
        </div>
        <div>
          <small className={styles.copyright}>
            &copy; Samy Abouseda {currentyear}
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { Component } from "react";
import styles from "./SectionSubtitle.module.css";
import Fade from "react-reveal/Fade";

class SectionSubtitle extends Component {
  render() {
    const { red, hero, light, dark, children } = this.props;

    let classNames = `
      ${styles.section__subtitle} 
      ${light ? styles["section__subtitle--light"] : ""}
      ${dark ? styles["section__subtitle--dark"] : ""}
      ${red ? styles["section__subtitle--red"] : ""} 
      ${hero ? styles["section__subtitle--hero"] : ""} 
    `;

    return (
      <Fade bottom>
        <h3 className={classNames}>{children}</h3>
      </Fade>
    );
  }
}

export default SectionSubtitle;

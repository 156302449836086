import React from "react";
import Header from "../components/Header/Header";
import Section from "../components/Section/Section";
import Footer from "../components/Footer/Footer";
import posts from "../assets/texts/posts";
import ContactCard from "../components/Footer/ContactSection/Card";
import styles from "./Pages.module.css";
import { HashLink as Link } from "react-router-hash-link";

const Blog = () => {
  return (
    <div>
      <Section pageHeader>
        <Header />
      </Section>
      <section className={styles.blog}>{renderBlogPosts()}</section>
      <Footer />
    </div>
  );
};

const renderBlogPosts = () => {
  return posts.map(post => renderBlogPost(post));
};

const renderBlogPost = post => {
  return (
    <ContactCard full>
      <img className={styles.post__img} src={post.img} />
      <h2 className={styles["post__prev-title"]}>{post.title}</h2>
      <h4 className={styles["post__prev-txt"]}>{post.excerpt}</h4>
      {/*<a className={styles.post__link} href={post.url}>*/}
      {/*Lire*/}
      {/*</a>*/}
      <div className={styles.post__link}>
        <Link to={post.url}>Lire</Link>
      </div>
    </ContactCard>
  );
};

export default Blog;

import React, { Component } from "react";
import styles from "./SectionTitle.module.css";
import Fade from "react-reveal/Fade";

class SectionTitle extends Component {
  render() {
    const { hero, overImage, light, dark, children } = this.props;

    let classNames = `
      ${styles["section-title"]}
      ${light ? styles["section-title--light"] : ""}
      ${dark ? styles["section-title--dark"] : ""}  
      ${hero ? styles["section-title--hero"] : ""}  
      ${overImage ? styles["section__title--over-image"] : ""}  
    `;

    if (hero)
      return (
        <Fade bottom>
          <h1 className={classNames}>{children}</h1>
        </Fade>
      );
    return (
      <Fade bottom>
        <h2 className={classNames}>{children}</h2>
      </Fade>
    );
  }
}

export default SectionTitle;

import React, { Component } from "react";
import styles from "./SectionText.module.css";
import Fade from "react-reveal/Fade";

class SectionText extends Component {
  render() {
    const { light, dark, overImage, twoColumns, children } = this.props;

    let classNames = `
      ${styles.section__text}
      ${light ? styles["section__text--light"] : ""} 
      ${dark ? styles["section__text--dark"] : ""}  
      ${overImage ? styles["section__text--over-image"] : ""} 
      ${twoColumns ? styles["section__text--two-columns"] : ""} 
    `;

    return (
      <Fade bottom>
        {" "}
        <div className={classNames}>{children}</div>
      </Fade>
    );
  }
}

export default SectionText;

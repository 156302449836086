import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Card from "../components/Footer/ContactSection/Card/ContactCard";
import {
  Section,
  SectionTitle,
  SectionSubtitle,
  SectionText,
} from "../components/Section";
import images from "../assets/images";
import styles from "./Pages.module.css";

class HomePage extends Component {
  render() {
    return (
      <div className={styles.content}>
        <Section hero>
          <Header />
          <SectionSubtitle hero>
            Salut {"  "}
            <span role='img' aria-label='hello'>
              👋
            </span>{" "}
            Je suis
          </SectionSubtitle>
          <SectionTitle>Samy Abouseda</SectionTitle>
          <SectionSubtitle>
            "J'adore apprendre de nouveaux concepts et de nouvelles manières de
            faire les choses. J'aime également imaginer et réaliser de nouveaux
            outils informatique qui résolvent les problèmes du quotidien. Je
            crois fermement que la technologie devrait être utilisée pour le
            bien commun et que les équipes sont plus fortes que les individus ".
          </SectionSubtitle>
        </Section>

        <Section id='about' light>
          <Card full>
            <div
              className={styles.me}
              style={{
                backgroundImage: `url(${images.me})`,
              }}
            />
            <br />
            <SectionTitle dark>A propos de moi</SectionTitle>
            <SectionSubtitle dark>
              Développeur web full-stack et étudiant
            </SectionSubtitle>
            <SectionText dark twoColumns>
              <p>
                Je suis actuellement en dernière année de bachelor à la
                <a
                  href='https://www.hesge.ch/heg/formation-base/bachelors-science/informatique-gestion'
                  className={styles.post__link}
                >
                  {" "}
                  HEG 
                </a>
                en Informatique de Gestion . En dehors des cours, je travaille
                en tant que freelance pour créer des sites e-commerce pour les
                petites entreprises.{" "}
              </p>

              <p>
                J'ai récemment commencé à créer des applications web en
                utilisant 
                <a className={styles.post__link} href='https://reactjs.org/'>
                  {" "}
                  ReactJS 
                </a>
                et 
                <a
                  className={styles.post__link}
                  href='https://www.djangoproject.com/'
                >
                  {" "}
                  Django
                </a>
                . J'aime créer de nouveaux logiciels et applications qui
                permettent de répondre aux problèmes du quotidien.{" "}
              </p>

              <p>
                Je suis notamment en train de réaliser une plateforme
                de trading sans frais basée sur la blockchain. Un domaine qui
                m'intéresse énormément est le machine-learning. J'ai eu
                l'occasion d'en faire durant mon cursus à la HEG et c'est un
                domaine dans lequel je souhaite progresser et approfondir mes
                connaissances.
              </p>

              <p>
                Cependant, avant de me lancer dans l'apprentissage du
                machine-learning, je me concentre sur l'architecture logicielle
                afin de pouvoir réaliser des systèmes robustes, de qualité et
                viable sur le long terme.
              </p>
            </SectionText>
          </Card>
        </Section>

        <Section hero id='experiences_and_studies' narrow>
          <SectionTitle hero>Expérience et compétences</SectionTitle>
          <section>
            <Card>
              <SectionText dark>
                <p>Full stack web developer, Swiss Dental Clinic</p>
                <span>mar. 2019 – current</span>
              </SectionText>
            </Card>

            <Card>
              <SectionText dark>
                <div className={styles.post__meta}>
                  <p
                    className={[
                      styles["post__meta-item"],
                      styles["post__meta--first-child"],
                    ]}
                  >
                    Full stack web developer{" "}
                    <a href={"https://gallogiant.com"}>Gallogiant</a>
                  </p>
                  <span>sept. 2017 – juil. 2018</span>
                </div>
                Establishment of an e-commerce platform on Wordpress - Design of
                the website - Development of a tailor-made theme - Development
                of plugins containing site-specific features Maintenance
              </SectionText>
            </Card>
            <Card>
              <SectionText dark>
                <div className={styles.post__meta}>
                  <p>Full stack web developer, Marquise Paris</p>
                  <span>sept. 2016 – juil. 2017</span>
                </div>
                Development of fully customized e-commerce platform (on
                Wordpress) Development of a tailor-made theme Design,
                development and implementation of front-end and back-end (JS and
                PHP) development of plugins containing site-specific features
                User support and website administration
              </SectionText>
            </Card>

            <Card>
              <SectionText dark>
                <div className={styles.post__meta}>
                  <p>Business Assistant, Procter & Gamble, Oral Care</p>
                  <span>juil. 2015 – juil. 2016</span>
                </div>
                Weekly E-Commerce scorecards maintenance and reporting. - Market
                analysis and optimization proposition (manual toothbrushes in
                Italy) - Optimization of the process of ordering and sending
                samples - Intranet website optimization (UX & Features) -
                Creation of POSM (point of sale material) - Administrative
                support to the PS, Marketing team and MS&P Team - Management of
                respective agendas, planning and scheduling of meetings
                (Internal/External) - Travel bookings and visa requests. -
                Preparation of expense reports. - Events management and
                planning. - Maintenance of data in multiple systems.
              </SectionText>
            </Card>
          </section>

          <section>
            <Card>
              <SectionText dark>
                <p>HEG de Genève, Business Information Systems (BASc)</p>
                <p>
                  Ecole de Commerce Aimée-Stitelmann, maturité professionnelle
                  and CFC (Federal Vocational Baccalaureate and Federal VET
                  Diploma)
                </p>
              </SectionText>
            </Card>
          </section>
        </Section>

        <Section id='vision' light narrow>
          <SectionTitle dark>Vision</SectionTitle>
          <SectionSubtitle dark>Perspectives d’avenir</SectionSubtitle>
          <h2>Court terme</h2>
          <SectionText dark twoColumns>
            Je me concentre actuellement sur l'apprentissage de l'architecture
            logicielle, qui d'après moi, est ce qui permet de pouvoir réaliser
            des systèmes robustes, de qualité et viable sur le long terme. Une
            fois que j'aurais acquis suffisamment d'expérience dans ce domaine
            et dans la réalisation de logiciel en général, je souhaite me lancer
            sur des sujets plus complexes. En attendant, j'ai du pain sur la
            planche et je vais d'abord réaliser la plateforme de trading évoquée{" "}
            <a href='#about' className={styles.post__link}>
              plus haut
            </a>{" "}
            .
          </SectionText>
          <br />
          <h2>Moyen terme</h2>
          <SectionText dark twoColumns>
            Je suis très intéressé par le machine-learning et les possibilités
            qu'offre le traitement d'une masse importante de données dans les
            applications financières ainsi que dans des applications utilisées à
            des fins de marketing . J'ai eu l'occasion d'en faire durant mon
            cursus à la HEG et c'est un domaine dans lequel je souhaite
            progresser et approfondir mes connaissances.
          </SectionText>
          <br />
          <h2>Long terme</h2>
          <SectionText dark twoColumns>
            Je m'imagine bien au sein d'une start-up que j'aurais créée ou bien
            tout simplement intégrée à ses débuts. Je pense que c'est un univers
            qui me conviendrait parfaitement. Le parfait équilibre entre un
            besoin de créativité constant et la nécessité de se tenir à jour en
            permanence. Que ce soit au niveau des nouvelles technologies qui ne
            cessent de voir le jour, ou bien tout simplement au niveau des
            besoins du marché qui changent constamment.
          </SectionText>
        </Section>

        <Footer id='contact' />
      </div>
    );
  }
}

export default HomePage;
